import { type App } from 'vue'
import AppDatepicker from './app-datepicker.vue'
import AppInput from './app-input.vue'
import AppNumeric from './app-numeric.vue'
import FaIcon from './fa-icon.vue'

export default {
  install (app: App) {
    app.component('AppDatepicker', AppDatepicker)
    app.component('AppInput', AppInput)
    app.component('AppNumeric', AppNumeric)
    app.component('FaIcon', FaIcon)
  },
}

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    AppDatepicker: typeof AppDatepicker,
    AppInput: typeof AppInput,
    AppNumeric: typeof AppNumeric,
    FaIcon: typeof FaIcon,
  }
}
