/**
 * Update textarea height onkeypress enter.
 */
const textareaInput = (event: Event) => {
  if (!event.currentTarget) return
  const element = event.currentTarget as HTMLInputElement
  element.style.height = ''
  element.style.height = element.scrollHeight + 'px'
}
const mounted = function (el: HTMLElement) {
  const input = el.querySelector<HTMLInputElement>('textarea,input')
  if (input) {
    input.addEventListener('input', textareaInput)
  }
}
const unmounted = function (el: HTMLElement) {
  const input = el.querySelector<HTMLInputElement>('textarea,input')
  if (input) {
    input.removeEventListener('input', textareaInput)
  }
}
export default {
  mounted,
  unmounted,
}
