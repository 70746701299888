<script setup lang="ts">
  import { Money3Component as VMoney } from 'v-money3'
  import { computed } from 'vue'
  import { fmt, randomId } from '@/functions'

  const props = defineProps<{
    id?: string,
    type?: string,
    label?: string,
    placeholder?: string,
    required?: boolean,
    error?: string | string[],
    maxLength?: number,
    rows?: number,
    disabled?: boolean,
    autocomplete?: string,
    readonly?: boolean,
    options?: Record<string, unknown>,
  }>()

  const model = defineModel<string>({ required: true })
  const emit = defineEmits<{
    (e: 'input', event: Event): void,
  }>()

  const onInput = (event: Event) => {
    emit('input', event)
  }

  const defaultId = randomId()
  const inputId = computed(() => props.id ?? defaultId)
</script>

<template>
  <div>
    <label
      v-if="label"
      :for="inputId"
      class="form-label">
      {{ label }}
      <span
        v-if="required"
        class="text-red-600">
        *
      </span>
    </label>
    <div class="relative flex items-center">
      <textarea
        v-if="type === 'textarea'"
        :id="inputId"
        v-model="model"
        class="form-input scrollbar-none resize-none"
        :disabled="disabled"
        :placeholder="placeholder"
        :rows="rows ?? 3"
        :required="required"
        :readonly="readonly"
        :maxlength="maxLength"
        @input="onInput"></textarea>
      <v-money
        v-else-if="type === 'number'"
        :id="inputId"
        v-model="model"
        class="form-input"
        :required="required"
        :disabled="disabled"
        :readonly="readonly"
        v-bind="options"></v-money>
      <input
        v-else
        :id="inputId"
        v-model="model"
        class="form-input"
        :disabled="disabled"
        :autocomplete="autocomplete ?? 'off'"
        :type="type"
        :placeholder="placeholder"
        :readonly="readonly"
        :required="required"
        :maxlength="maxLength"
        @input="onInput">
      <div
        v-if="maxLength !== undefined"
        class="absolute bottom-1 right-2 bg-white text-3xs text-gray-500">
        {{ fmt.number(maxLength - model.length) }}
      </div>
      <slot name="inline"></slot>
    </div>
    <slot></slot>
  </div>
</template>

<style lang="postcss" scoped>
  /* copied to app-numeric, when changing these value don't forget to sync there */
  .form-input {
    @apply block h-9 w-full rounded px-4 text-sm;
    @apply focus:input-ring border placeholder:text-gray-300;

    textarea& {
      @apply py-2;
    }
  }

  .form-label {
    @apply block text-left text-xs font-bold mb-1;
  }
</style>
