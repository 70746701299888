<script setup lang="ts">
  import { computed } from 'vue'
  import { fmt, randomId } from '@/functions'

  const props = defineProps<{
    id?: string,
    type?: string,
    label?: string,
    placeholder?: string,
    required?: boolean,
    error?: string | string[],
    disabled?: boolean,
    modelValue: Date | null,
    min?: Date,
  }>()
  const emit = defineEmits<{
    (e: 'update:modelValue', value: Date | null): void,
  }>()

  const model = computed({
    set: (val) => {
      emit('update:modelValue', val ? new Date(val) : null)
    },
    get: () => (props.modelValue ? fmt.date(props.modelValue, 'yyyy-MM-dd') : ''),
  })

  const formattedDate = computed(() => {
    if (props.modelValue) {
      return fmt.date(props.modelValue, 'd MMM yyyy')
    }

    return ''
  })

  const defaultId = randomId()
  const inputId = computed(() => props.id ?? 'text-' + defaultId)

  const openDatePicker = () => {
    const el = document.getElementById(inputId.value)
    if (el && el instanceof HTMLInputElement) {
      el.showPicker()
    }
  }
</script>

<template>
  <div class="relative">
    <input
      :id="inputId"
      v-model="model"
      type="date"
      class="absolute inset-0 opacity-0 -z-1"
      :disabled="disabled"
      :required="required"
      :min="min ? fmt.date(min, 'yyyy-MM-dd') : undefined"
      tabindex="9999">
    <app-input
      :id="inputId"
      :model-value="formattedDate"
      :label="label"
      :error="error"
      readonly
      :placeholder="placeholder"
      @focus="openDatePicker()"
      @click="openDatePicker()">
      <template #inline>
        <div
          class="datepicker-icon"
          :class="{
            'text-gray-300': disabled,
          }">
          <fa-icon icon="i-far-calendar"></fa-icon>
        </div>
      </template>
    </app-input>
  </div>
</template>

<style lang="postcss" scoped>
  .datepicker-icon {
    @apply absolute bottom-0 flex items-center text-xs leading-0 left-3 pointer-events-none;
    @apply text-gray;
    @apply h-9; /* height of form-input */
  }
  :deep() {
    input.form-input {
      @apply pl-8;
    }
  }
</style>
