<script setup lang="ts">
  import { computed } from 'vue'

  const config = {
    // masked: false,
    // prefix: '',
    // suffix: '',
    thousands: '.',
    decimal: ',',
    precision: 0,
    // disableNegative: false,
    // disabled: false,
    // min: null,
    // max: null,
    // allowBlank: false,
    // minimumNumberOfCharacters: 0,
    // shouldRound: true,
    // focusOnRight: false,
  }
  const modelValue = defineModel<number>({ required: true })
  const model = computed({
    get: () => modelValue.value.toString(),
    set: (val: string) => {
      modelValue.value = Number(val)
    },
  })
</script>

<template>
  <app-input
    v-model="model"
    type="number"
    :options="config">
    <slot></slot>
    <template #inline>
      <slot name="inline"></slot>
    </template>
  </app-input>
</template>
